const routes = [

	{
		path: '/login',
		component: () => import('../pages/Login.vue'),
		name: 'login',
		meta: {
			noheader: true,
			guest: true
		}
	},
	{
		path: '/dashboard',
		component: () => import('../pages/Dashboard.vue'),
		name: 'dashboard',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/employee/:id?',
		component: () => import('../pages/employee/Employee.vue'),
		name: 'employee',
		props: { default: true },
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/addemployee',
		component: () => import('../pages/employee/EmployeeForm.vue'),
		name: 'addemployee',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/party/:id?',
		component: () => import('../pages/party/Party.vue'),
		name: 'party',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/addparty',
		component: () => import('../pages/party/PartyForm.vue'),
		name: 'addparty',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/customer/:id?',
		component: () => import('../pages/customer/Customer.vue'),
		name: 'customer',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/customerdetails',
		component: () => import('../pages/customer/CustomerDetail.vue'),
		name: 'customerdetails',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/addcustomer',
		component: () => import('../pages/customer/CustomerForm.vue'),
		name: 'addcustomer',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/category',
		component: () => import('../pages/category/Category.vue'),
		name: 'category',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/stocks',
		component: () => import('../pages/stock/StockList.vue'),
		name: 'stocks',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/salesmanager',
		component: () => import('../pages/salesmanager/SalesManager.vue'),
		name: 'salesmanager',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/viewsales/:id?',
		component: () => import('../pages/salesmanager/ViewSales.vue'),
		name: 'viewsales',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/addcategory',
		component: () => import('../pages/category/CategoryForm.vue'),
		name: 'addcategory',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/addsubcategory',
		component: () => import('../pages/category/SubCategoryForm.vue'),
		name: 'addsubcategory',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/addcompany',
		component: () => import('../pages/category/CompanyForm.vue'),
		name: 'addcompany',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/subcategory',
		component: () => import('../pages/category/SubCategory.vue'),
		name: 'subcategory',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/product',
		component: () => import('../pages/product/Product.vue'),
		name: 'product',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/addproduct',
		component: () => import('../pages/product/ProductForm.vue'),
		name: 'addproduct',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/editproduct',
		component: () => import('../pages/product/ProductEdit.vue'),
		name: 'editproduct',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/schemes',
		component: () => import('../pages/scheme/Schemes.vue'),
		name: 'schemes',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/addschemes',
		component: () => import('../pages/scheme/SchemeForm.vue'),
		name: 'addschemes',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/ongoingschemes/:id?',
		component: () => import('../pages/ongoingschemes/OnGoingSchemes.vue'),
		name: 'ongoingschemes',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/addongoingschemes',
		component: () => import('../pages/ongoingschemes/OnGoingSchemeForm.vue'),
		name: 'addongoingschemes',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/manageaccess',
		component: () => import('../pages/access/ManageAccess.vue'),
		name: 'manageaccess',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/manageappaccess',
		component: () => import('../pages/access/ManageAppAccess.vue'),
		name: 'manageappaccess',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/scheduled',
		component: () => import('../pages/scheduled/Scheduled.vue'),
		name: 'scheduled',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/payment/:isactive',
		component: () => import('../pages/payment/Payment.vue'),
		name: 'payment',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
		{
		path: '/advisor',
		component: () => import('../pages/advisor/Advisor.vue'),
		name: 'advisor',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/deactiveadvisor',
		component: () => import('../pages/advisor/DeactiveAdvisors.vue'),
		name: 'deactiveadvisor',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/defaulterongoingschemes',
		component: () => import('../pages/ongoingschemes/DefaulterOnGoingSchemes.vue'),
		name: 'defaulterongoingschemes',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/refundschemes',
		component: () => import('../pages/ongoingschemes/RefundOnGoingSchemes.vue'),
		name: 'refundschemes',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/emimanager',
		component: () => import('../pages/emimanager/EmiManager.vue'),
		name: 'emimanager',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/customerdefaulter',
		component: () => import('../pages/customer/CustomerDefaulter.vue'),
		name: 'customerdefaulter',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/broadcast',
		component: () => import('../pages/broadcast/Broadcast.vue'),
		name: 'broadcast',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},

	{
		path: '/directpayment',
		component: () => import('../pages/directpayment/DirectPayment.vue'),
		name: 'directpayment',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/daybook',
		component: () => import('../pages/daybook/DayBook.vue'),
		name: 'daybook',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/addpurchase',
		component: () => import('../pages/purchase/PurchaseForm.vue'),
		name: 'addpurchase',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/purchasestock',
		component: () => import('../pages/purchase/PurchaseStock.vue'),
		name: 'purchasestock',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/invoice',
		component: () => import('../pages/invoice/Invoice.vue'),
		name: 'invoice',
		meta: {
			noheader: true,
			requiresAuth: true
		}
	},
	{
		path: '/dues/:pagetype?',
		component: () => import('../pages/overdues/DuesReport.vue'),
		name: 'dues',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/overdues',
		component: () => import('../pages/overdues/OverDues.vue'),
		name: 'overdues',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/emioverdues',
		component: () => import('../pages/emioverdues/EmiOverDue.vue'),
		name: 'emioverdues',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/commissionpayment',
		component: () => import('../pages/advisor/CommissionPayment.vue'),
		name: 'commissionpayment',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/customerdata',
		component: () => import('../pages/crm/CustomerData.vue'),
		name: 'customerdata',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/crmschedule',
		component: () => import('../pages/crm/CrmScheduled.vue'),
		name: 'crmschedule',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/crmdispose',
		component: () => import('../pages/crm/CrmDispose.vue'),
		name: 'crmdispose',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/complaint',
		component: () => import('../pages/complaint/NewComplaint.vue'),
		name: 'complaint',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/pendingcomplaint',
		component: () => import('../pages/complaint/PendingComplaint.vue'),
		name: 'pendingcomplaint',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/disposecomplaint',
		component: () => import('../pages/complaint/DisposeComplaint.vue'),
		name: 'disposecomplaint',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/newleads',
		component: () => import('../pages/leads/NewLead.vue'),
		name: 'newleads',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/pendingcjso',
		component: () => import('../pages/leads/PendingCJSO.vue'),
		name: 'pendingcjso',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/scheduledleads',
		component: () => import('../pages/leads/ScheduledLeads.vue'),
		name: 'scheduledleads',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/disposecjso',
		component: () => import('../pages/leads/DisposeCJSO.vue'),
		name: 'disposecjso',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/pdftest',
		component: () => import('../pages/overdues/PdfTest.vue'),
		name: 'pdftest',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/closedaccount/:status?',
		component: () => import('../pages/accounts/ClosedSale.vue'),
		name: 'closedaccount',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/updatermn',
		component: () => import('../pages/updatermn/UpdateRMN.vue'),
		name: 'updatermn',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/downline/:id?',
		component: () => import('../pages/advisor/DownLine.vue'),
		name: 'downline',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/smsreport',
		component: () => import('../pages/sms/SmsReport.vue'),
		name: 'smsreport',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/loanfollowed',
		component: () => import('../pages/loanFollowed/LoanAccountFollowed.vue'),
		name: 'loanfollowed',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/commentsverify/:isdraft',
		component: () => import('../pages/verification/EmiCommentsVerify.vue'),
		name: 'commentsverify',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/paymentstatus/:isdraft',
		component: () => import('../pages/payment/PaymentReport.vue'),
		name: 'paymentstatus',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/jobsform',
		component: () => import('../pages/jobs/JobsForm.vue'),
		name: 'jobsform',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/jobs',
		component: () => import('../pages/jobs/Jobs.vue'),
		name: 'jobs',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/scheduledjobs',
		component: () => import('../pages/jobs/CrmScheduled.vue'),
		name: 'scheduledjobs',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/rejectjobs',
		component: () => import('../pages/jobs/RejectJob.vue'),
		name: 'rejectjobs',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/neworder',
		component: () => import('../pages/order/NewOrder.vue'),
		name: 'neworder',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/vieworder',
		component: () => import('../pages/order/PendingOrder.vue'),
		name: 'vieworder',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/dispatchorder',
		component: () => import('../pages/order/DispatchedOrder.vue'),
		name: 'dispatchorder',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/servicecenter',
		component: () => import('../pages/servicecenter/ServiceCenter.vue'),
		name: 'servicecenter',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/professionmaster',
		component: () => import('../pages/customer/ProfessionMaster.vue'),
		name: 'professionmaster',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/templates',
		component: () => import('../pages/whatsapp/WhatsAppTemplates.vue'),
		name: 'whatsapp/temlates',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/stocktransfer',
		component: () => import('../pages/store/StockTransfer.vue'),
		name: 'stocktransfer',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/transferhistory',
		component: () => import('../pages/store/StockTransferView.vue'),
		name: 'transferhistory',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/partyledger',
		component: () => import('../pages/party/PartyLedger.vue'),
		name: 'partyledger',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/expense-head',
		component: () => import('../pages/expense/ExpenseHeads.vue'),
		name: 'expense-head',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/expense-form',
		component: () => import('../pages/expense/ExpenseForm.vue'),
		name: 'expense-form',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/salary-slip-form',
		component: () => import('../pages/salary/SalarySlipForm.vue'),
		name: 'salary-slip-form',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	{
		path: '/salary-slip',
		component: () => import('../pages/salary/SalarySlips.vue'),
		name: 'salary-slip',
		meta: {
			noheader: false,
			requiresAuth: true
		}
	},
	]
export default routes;
