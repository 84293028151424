<template>
    <nav class="navbar" role="navigation" style="z-index:1">
            <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
            <a class="navbar-brand" href="#">
            <i class="fa fa-list btn-nav-toggle-responsive text-white"></i> 
            <span class="logo">EMI Vs<strong>di</strong>gi <i class="fa fa-bookmark"></i>
            </span></a>
        </div>
        <!-- Collect the nav links, forms, and other content for toggling -->
       <div class="collapse navbar-collapse ">
       
            <ul class="nav navbar-nav user-menu navbar-right">
                <li>
                    <div  style="margin-top:29px;margin-right: 50px;">
                        <select-store></select-store>
                        <!-- <div class="row" >
                            <div>
                                <select class="form-control form-cascade-control input-small .dropdown-menu">
                                    <option>Main</option>
                                    <option>Factory</option>
                                </select>
                            </div>
                        </div> -->
                    </div>
                </li>
                <li v-if="loginusercheck(151)">
                    <div class="" id="div_search" role="navigation" style="margin-top:25px;">
                        <input v-model="searchstring" @keypress.enter="search()" class="form-control form-cascade-control input-small TXTLAN" style="float:left;display:inline-block;width:70%;height:31px;margin-top:4px;">
                        <button type="submit" @click="search()" class="btn bg-success text-white username btnsearch" style="float:left;display:inline-block;">Search</button>
                    </div>
                </li>
                <li>
                    <ul class="dropdown">
                        <div>
                            <li><a href="#" v-if="loggedinuser!=null"><span class="username">   <i class="fa fa-user fa-lg"></i>   <span id="USERNAME">{{loggedinuser.name}}</span> 
                                <i class="fa fa-chevron-down" aria-hidden="false"></i> </span></a>
                                <ul>
                                    <li><a href="#"><i class="fa fa-user"></i> My Profile</a></li>
                                    <li><a href="#"><i class="fa fa-cogs"></i> Change Password</a></li>
                                    <li class="divider"></li>
                                    <li><a href="#" @click="logout()" class="text-danger"><i class="fa fa-lock"></i> Logout</a></li>
                                </ul>
                            </li>
                        </div>
                    </ul>
                   
                </li>
            </ul>
       </div>
        <!-- /.navbar-collapse -->
    </nav> <!-- /.navbar -->
</template>

<script>
import { mapGetters } from 'vuex'
import SelectStore from '../../pages/store/SelectStore.vue'
import Constants from '../utilities/Constants.vue'
export default {
    components:{SelectStore},
    mixins:[Constants],
    data() {
        return {
            searchstring:'',
            width:window.innerWidth,
            height:window.innerHeight
        }
    },
    computed:{
        ...mapGetters([
            'loggedinuser'                                           
	    ]),
       
    },
    methods:{
        search(){
            if(this.searchstring==''){
                this.$notify({ text:'Enter search value.', type:'error' })   
            }else {
                this.$store.commit("assignviewno",1)
                this.$store.commit("assignloadingstatus",1)
                let param = {searchstring:this.searchstring}
                window.axios.post('api/searchallrecords',param)
                    .then(response=>this.processResponse(response.data))
                    .catch(error=>console.log(error));
            }
        },
        processResponse(data){
            this.$store.commit("assignloadingstatus",0)
            if(data.success==true && data.data!=null){
                // console.log(this.$route)
                // console.log("params",this.$route.params.id)
                // console.log("redirect",data.redirect)
                console.log("routes",this.$route.name)
                // console.log("searchstring",this.searchstring)
                // console.log(data)
                if(!(data.redirect==this.$route.name && this.$route.params.id==this.searchstring)){
                    this.$router.push({name:data.redirect,params:{id:this.searchstring}})
                }
                this.$store.commit("assignsearchdata",data)
                // console.log(data.redirect)
                if(data.redirect=='customer'){
                    this.$store.commit('assigncustomers',data.data)
                }
                if(data.redirect=='ongoingschemes'){
                    this.$store.commit('assignongoingschemes',data.data)
                }
            }else {
                this.$notify({
                    text:'Data Not Found',
                    type:'error',
                    top:'100',
                    duration:'80000',
                    speed:'800',
                })
            }
        },
        logout(){
			localStorage.removeItem("token");
            this.$router.push({ path: "login" });
            this.$store.commit('assignloadingstatus',0)
		},
    }
}
</script>