require('./bootstrap');

import Vue from 'vue'
import VueRouter from 'vue-router';
import router from './router/index'
import store from './store/index';
import Form from './core/Form';
import App from './App.vue'
import "html2canvas/dist/html2canvas.js"
import VModal from 'vue-js-modal';
import 'vue-js-modal/dist/styles.css'
import Notifications from 'vue-notification'
import JsonExcel from "vue-json-excel";
import Vuelidate from 'vuelidate'
import VCalendar from 'v-calendar';
import VueGeolocation from 'vue-browser-geolocation';
import Vidle from 'v-idle'

var infiniteScroll =  require('vue-infinite-scroll');

Vue.config.productionTip = false

window.Form = Form;
Vue.use(VueRouter)
Vue.prototype.$http = window.axios;
Vue.use(VModal);
Vue.use(infiniteScroll)
Vue.use(VueGeolocation);
Vue.use(Vidle)
Vue.use(Vuelidate);
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
Vue.component("downloadExcel", JsonExcel);
Vue.use(Notifications)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
