<template>
    <div class="m-top-8">
        <select v-model="selectedstore" @change="storeFun()" :disabled="this.loggedinuser?.role>2?false:true"
            class="form-control form-cascade-control input-small .dropdown-menu">
            <option v-for="(option) in stores" v-bind:value="option.id" v-text="option.name" :key="option.id">
            </option>
        </select>
    </div>
</template>
<script>
// import axios from 'axios';
import { mapGetters } from 'vuex'
import axios from 'axios';
import Constants from '../../components/utilities/Constants.vue';
export default {
    mixins: [Constants],
    computed: {
        ...mapGetters(['viewno', 'selectedstoreid', 'stores', 'loggedinuser']),

        // selectedstore: function () {
        //     let storeid = 0
        //     if (this.selectedstoreid != null && this.selectedstoreid != 0) {
        //         storeid = this.selectedstoreid
        //         console.log("return store id - ", storeid)
        //         return storeid;
        //     } else {
        //         if (this.stores.length > 0) {
        //             this.$store.commit("assignselectedstoreid", this.stores[0].id);
        //             storeid = this.stores[0].id
        //             console.log("return store id 2 - ", storeid)
        //             return storeid;
        //         }
        //     }
        //     return storeid;
        // }

        selectedstore: {
            get() {
                if (this.selectedstoreid != "") {
                    return this.selectedstoreid;
                } else {
                    if (this.stores.length > 0) {
                        if (this.loggedinuser?.id>0) {
                            this.stores.forEach(element => {
                                if (element?.userid == this.loggedinuser?.id) {
                                    this.$store.commit("assignselectedstoreid", element?.id);
                                }
                            });
                            if(this.selectedstoreid==0){
                                this.$store.commit("assignselectedstoreid", this.stores[0].id);
                            }
                        }
                        return this.selectedstoreid;
                    } else {
                        console.log("no store")
                    }
                }
                return this.selectedstoreid;
            },
            set(val) {
                this.$store.commit("assignselectedstoreid", val);
            }
        }

    },
    methods: {
        storeFun() {
            console.log("hello", this.selectedstore)
            console.log("logged in user", this.loggedinuser.id)
            let param = { selectedstore: this.selectedstoreid, fy: this.getCurrentFinancialYear() ?? 2024 }
            axios.post('api/stock/forstore2', param)
                .then((response) => this.processResponse(response.data))
                .catch((error) => console.log(error))

        },
        processResponse(data) {
            this.$store.commit('assignstocklist', data)
        }
        // processResponse(data) {
        //     this.$store.commit("assignstores", data);
        //     this.$store.commit("assignselectedstoreid", data[0].id);
        // },
        // showError() {
        //     this.$store.commit('assignloadingstatus', 0)
        //     alert("Something went wrong, please try again.")
        // }
    }
}
</script>