<template>
	<div id="app">
		<top-bar v-if="!$route.meta.noheader"></top-bar>
		
		<navbar v-if="!$route.meta.noheader"></navbar>
		
		<notifications position="top right" />
		<!-- Log Out On idle Check -->
		<div class="content">
			<loading-image />
			<div class="logout-1">
				<label for="">Log out In:</label>
				<v-idle
				@idle="onidle"
				@remind="onremind"
				:loop="true"
				:reminders="[10, 15]"
				:wait="5"
				:duration="1800" />
			</div>
		<!-- ------------------------- -->
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
import TopBar from "./components/bars/TopBar.vue";
import Navbar from "./components/bars/Navbar.vue";
import LoadingImage from './components/utilities/LoadingModal.vue'
export default {
	name: "App",
	components: { Navbar, TopBar, LoadingImage },
	data() {
		return {
			timerCount:15
		}
	},
	mounted() {
		this.$store.commit('assignloadingstatus', 1)
		this.$notify({ text: 'Welcome To VSDigi', type: 'Success' })
		let master = localStorage.getItem("master");
		if (master != null) {
			console.log("stage1")
			this.$store.commit("assignmaster", master);
		} else {
			this.$store.dispatch("fetchmaster");
			//console.log("stage2")
		}
		//if logged in the move to dashboard
		let token = localStorage.getItem("token");
		//console.log(token);
		if (token != null) {

			//validate the token
			this.$http.interceptors.request.use(
				(config) => {
					const token = localStorage.getItem("token");
					if (token) {
						config.headers["Authorization"] = `Bearer ${token}`;

					}
					config.headers["projectid"] = 1;
					return config;
				},
				(error) => {
					return Promise.reject(error);
				}
			);
			this.$store.commit('assignloadingstatus', 1)
			this.$http
				.post("api/verifytoken")
				.then((response) => this.processResponse(response.data))
				.catch((error) => console.log(error));
		} else {
			this.$router.push({ path: "login" });
		}
	},
	methods: {
		processResponse(data) {
			console.log(data)
			if (data) {
				this.$store.commit('assignloadingstatus', 0)
				this.$store.dispatch("getloggedinuser");
				this.$store.dispatch("getcompanyuser");
				this.$store.dispatch("fetchstores")
				//this.$router.push({ path:'dashboard'})
			} else {
				localStorage.removeItem("token");
				this.$store.commit('assignloadingstatus', 0)
				this.$router.push({ path: "login" });
				//this.$router.push('dashboard')
			}
		},
		onidle() {
			let token=localStorage.getItem("token");
			if(token!=null){
				localStorage.removeItem("token");
				alert('You have been logged out');
				this.$router.push({name:'login'})
			}
			
		},
		onremind(time) {
			// alert seconds remaining to 00:00
			if(time==15){
				setTimeout(() => {
					this.timerCount--;
					this.$notify({
						text:`Logout In ${this.timerCount}`,
						type:'error',
						duration:'1000',
						speed:'800',
						clean:true
                    
					})
				}, 1000);
				
			}
			console.log("log out Time in :"+time)
		}
	},
};
</script>
<style>
@import "./assets/styles/login.css";
@import "./assets/styles/style.min.css";
@import "./assets/styles/bootstrap.min.css";
@import "./assets/styles/mystyle.min.css";
@import "./assets/styles/bgcolor.css";

@import "//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
.logout-1{
	position: absolute;
    top: 95px;
    left: 2px;
	display: flex;
}
</style>

