import Vue from 'vue';
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		loggedinuser: null,
		categories: [],
		attributes: [],
		cartcount: 0,
		cartitems: [],
		storeproducts: [],
		filteredproducts: [],
		attributeproductcount: [],
		filters: [],
		filtervalues: {},
		filterstring: {},
		bannertypeimage: [],
		productstore: [],
		viewno: 1,
		levels: [],
		userids: '',
		balances: [],
		debituser: [],
		chargeableledger:[],
		complaints: [],
		custwalletheading: "Wallet Account Information",
		custumbgcolor: { 0: 'bg-info', 1: 'bg-primary', 2: 'bg-secondary', 3: 'bg-success', 4: 'bg-warning', 5: 'bg-info' },
		rechargeform: null,
		servicetype: 1,
		apibalance: 0,
		uploadedimage: null,
		userdetails: null,
		editvalue: null,
		editvaluebank: null,
		upi: [],
		bank: [],
		vehicletype: [],
		insurancetype: [],
		insuranceterm: [],
		loantype: [],
		occupation: [],
		insurancecomapny: [],
		operator: [],
		operatortype: [],
		stocklist:[],
		country: [],
		state: [],
		city: [],
		noofrecord:10,
		gasagency: [],
		orgtype: [],
		status: [],
		selectedtopupid: 1,
		team: 1,
		perpagerecord: 10,
		currentpage: 1,
		crtpage: 1,
		gender: '',
		menu: [],
		bloodgrp: [],
		maritalstatus: [],
		list: [],
		usermobiles: '',
		cmpbank: '',
		nomineerelation: [],
		widthrawstatus: '',
		paymentstatus: '',
		editbankimage: '',
		imageflag: 1,
		edititem: null,
		editmodal: null,
		editview: false,
		removeitems: '',
		employees: [],
		parties: [],
		sampleimage: [],
		customers: [],
		employeescount: [],
		partiescount: [],
		customerscount: 0,
		master: [],
		mobiles: [],
		selectedaddress: null,
		selectedwaddress: null,
		addressess: [],
		profileimage: null,
		idproofs: null,
		referraluser: null,
		schemes: [],
		ongoingschemes: [],
		accessmodules: [],
		selecteduserid: 0,
		advisors: [],
		downline: [],
		defaultschemes: [],
		refundschemes: [],
		defaultcustomers: [],
		allproducts: [],
		activeemployees: [],
		allpayments: [],
		directorypath: '',
		feature: [],
		newtax: [],
		bankdetail: [],
		loandetails: null,
		selectedproductid: null,
		loadingstatus: 0,
		defaultschemescount: 0,
		defaultcustomerscount: 0,
		activeemployeescount: 0,
		advisorscount: 0,
		hsnbasedtaxes: [],
		applicabletaxes: [],
		trantype: 1,
		trantypestring: { 1: "Purchase", 2: "Sale", 3: "Payment Received", 4: "Payment Given", 7: "Expense", 9: "Payment Transferred", 12: "Modified Balance" },
		allproductscount: 0,
		saletype: 2,
		transaction: null,
		transactionid: 0,
		ptransactions: [],
		stransactions: [],
		transactiondetails: [],
		purchasedetails: [],
		totalnetprice: 0,
		amountwithouttax: 0,
		totalnetpriceafterdiscount: 0,
		barcodeitem: null,
		batchcode: [],
		showbarcodemodal: false,
		updatebatchcode: [],
		companyuser: [],
		registers: [],
		searchdata: [],
		sumofremainingamount: 0,
		allpaymentscount: 0,
		totalsalecount: 0,
		noofrec: 10,
		allexcelpayments: [],
		allpaymentsum: 0,
		scheduleloanemi: [],
		scheduleloanemicount: [],
		downlinecount: 0,
		commissiondetails: [],
		advisorid: [],
		deactiveadvisors: [],
		crmschedule: [],
		crmschedulecount: 0,
		leads: [],
		stores: [],
		selectedstoreid: '',
		leadsedit: null,
		accountstatus: [],
		loanaccounttype: 0,
		filecomponents: [],
		imagetype: 0,
		controlValue: {
			showLayout: false,
			floatLayout: true,
			enableDownload: true,
			previewModal: true,
			paginateElementsByHeight: 1100,
			manualPagination: false,
			filename: 'Hee Hee',
			pdfQuality: 2,
			pdfFormat: 'a4',
			pdfOrientation: 'portrait',
			pdfContentWidth: '800px'
		},
		setmobileno: [],
		joblist: [],
		orderconversation: [],
		selectedadvisor: [],
		uploadids: [],
		companymaster: [],
		paymenttype: { 'cash': 2567, 'cashpayment': 273, 'googlepay': 2225, 'phonepe': 2227, 'cheque': 2229, 'rtgs': 2230, 'paytm': 6511, 'amazonpay': 6514, 'razorpay': 8630 },
		followedbydispatch: '',
	},
	mutations: {
		assignfollowedbydispatch(state, u) {
			state.followedbydispatch = u
		},
		assigncompanymaster(state, u) {
			state.companymaster = u
		},
		assignuploadids(state, u) {
			state.uploadids = u
		},
		assignselectedadvisor(state, u) {
			state.selectedadvisor = u
		},
		assignorderconversation(state, u) {
			state.orderconversation = u
		},
		assignjoblist(state, u) {
			state.joblist = u
		},
		assignsetmobileno(state, u) {
			state.setmobileno = u
		},
		assigncontrolValue(state, u) {
			state.controlValue = u;
		},
		assignloanaccounttype(state, u) {
			state.loanaccounttype = u;
		},
		assignaccountstatus(state, u) {
			state.accountstatus = u;
		},
		assignleads(state, u) {
			state.leads = u;
		},
		assignleadsedit(state, u) {
			state.leadsedit = u
		},
		assigncrmschedule(state, u) {
			state.crmschedule = u;
		},
		assigncrmschedulecount(state, u) {
			state.crmschedulecount = u;
		},
		assigndeactiveadvisors(state, u) {
			state.deactiveadvisors = u;
		},
		assignnoofrecord(state, u) {
			state.noofrecord = u;
		},
		assignadvisorid(state, u) {
			state.advisorid = u;
		},
		assignfilecomponents(state, u) {
			state.filecomponents = u
		},
		assigncommissiondetails(state, u) {
			state.commissiondetails = u;
		},
		assignscheduleloanemicount(state, u) {
			state.scheduleloanemicount = u;
		},
		assignscheduleloanemi(state, u) {
			state.scheduleloanemi = u;
		},
		assignallpaymentsum(state, u) {
			state.allpaymentsum = u;
		},
		assignallexcelpayments(state, u) {
			state.allexcelpayments = u;
		},
		assigntotalsalecount(state, u) {
			state.totalsalecount = u;
		},
		assignnoofrec(state, u) {
			state.noofrec = u;
		},
		assignallpaymentscount(state, u) {
			state.allpaymentscount = u;
		},
		assignsumofremainingamount(state, u) {
			state.sumofremainingamount = u;
		},
		assignsearchdata(state, u) {
			state.searchdata = u;
		},
		assignregisters(state, u) {
			state.registers = u;
		},
		assigncompanyuser(state, u) {
			state.companyuser = u;
		},
		assignshowbarcodemodal(state, u) {
			state.showbarcodemodal = u;
		},
		batchcode: state => {
			return state.batchcode;
		},
		updatebatchcode: state => {
			return state.updatebatchcode;
		},
		assignbarcodeitem(state, u) {
			state.barcodeitem = u;
		},
		updateappendpurchasedetails(state, u) {
			if (u.id == 0) {
				state.purchasedetails.push(u);
			} else {
				let index = state.purchasedetails.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.purchasedetails.splice(index, 1, u)
				} else
					state.purchasedetails.push(u);
			}

		},
		assigntotalnetpriceafterdiscount(state, u) {
			state.totalnetpriceafterdiscount = u;
		},
		assignamountwithouttax(state, u) {
			state.amountwithouttax = u;
		},
		assigntotalnetprice(state, u) {
			state.totalnetprice = u;
		},
		assignpurchasedetails(state, u) {
			state.purchasedetails = u;
		},
		assigntransactiondetails(state, u) {
			state.transactiondetails = u;
		},
		removetransactiondetails(state, u) {
			const index = state.transactiondetails.findIndex(block => block.id === u.id);
			state.transactiondetails.splice(index, 1)
		},
		addptransaction(state, u) {
			const index = state.ptransactions.findIndex(block => block.id === u.id);
			state.ptransactions.splice(index, 1, u)
		},
		removeptransaction(state, u) {
			const index = state.ptransactions.findIndex(block => block.id === u.id);
			state.ptransactions.splice(index, 1)
		},
		addstransaction(state, u) {
			const index = state.stransactions.findIndex(block => block.id === u.id);
			state.stransactions.splice(index, 1, u)
		},
		removestransaction(state, u) {
			const index = state.stransactions.findIndex(block => block.id === u.id);
			state.stransactions.splice(index, 1)
		},
		assignptransactions(state, u) {
			state.ptransactions = u;
		},
		assignstransactions(state, u) {
			state.stransactions = u;
		},
		assigntransaction(state, u) {
			state.transaction = u;
		},
		assigntransactionid(state, u) {
			state.transactionid = u;
		},
		assignsaletype(state, u) {
			state.saletype = u;
		},
		assignallproductscount(state, u) {
			state.allproductscount = u;
		},
		updatetrantype(state, u) {
			state.trantype = u;
		},
		assignapplicabletaxes(state, u) {
			state.applicabletaxes = u
		},
		assignhsnbasedtaxes(state, u) {
			state.hsnbasedtaxes = u
		},
		assignimagetype(state, u) {
			state.imagetype = u
		},
		assignadvisorscount(state, u) {
			state.advisorscount = u
		},
		assignstocklist(state,u){
			state.stocklist = u
		},
		assignactiveemployeescount(state, u) {
			state.activeemployeescount = u
		},
		assigndefaultcustomerscount(state, u) {
			state.defaultcustomerscount = u
		},
		assigndefaultschemescount(state, u) {
			state.defaultschemescount = u
		},
		assignloadingstatus(state, u) {
			state.loadingstatus = u
		},
		assignselectedproductid(state, u) {
			state.selectedproductid = u

		},
		assignloandetails(state, u) {
			state.loandetails = u
		},
		assignfeature(state, u) {
			state.feature = u
		},
		assigndirectorypath(state, u) {
			state.directorypath = u
		},
		assignselecteduserid(state, u) {
			state.selecteduserid = u
		},
		updateappendaccessmodules(state, u) {
			let index = state.accessmodules.findIndex(block => block.id == u.id)
			if (index > -1) {
				state.accessmodules.splice(index, 1, u)
			} else
				state.accessmodules.push(u);
		},
		assignaccessmodules(state, u) {
			state.accessmodules = u
		},
		updateappendongoingschemes(state, u) {
			let index = state.ongoingschemes.findIndex(block => block.id == u.id)
			if (index > -1) {
				state.ongoingschemes.splice(index, 1, u)
			} else
				state.ongoingschemes.push(u);
		},
		assignongoingschemes(state, u) {
			state.ongoingschemes = u
		},
		assignreferraluser(state, u) {
			state.referraluser = u;
		},
		updateappendidproofs(state, u) {

			let index = state.idproofs.findIndex(block => block.id == u.id)
			if (index > -1) {
				state.idproofs.splice(index, 1, u)
			} else
				state.idproofs.push(u);
			console.log(state.idproofs)
		},
		assignidproofs(state, u) {
			state.idproofs = u;
		},
		assignuploadedimage(state, u) {
			state.uploadedimage = u;
		},
		assignprofileimage(state, u) {
			state.profileimage = u;
		},
		updateappendaddressess(state, u) {
			if (u.id > 0) {
				let index = state.addressess.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.addressess.splice(index, 1, u)
				} else
					state.addressess.push(u);
			} else {
				if (u.localid > 0) {
					let index = state.addressess.findIndex(block => block.localid == u.localid)
					if (index > -1) {
						state.addressess.splice(index, 1, u)
					} else {
						//get the max local id from the list
						let maxid = 0;
						let newaddressess = state.addressess.filter(block => block.id == 0)
						if (newaddressess !== 'undefined') {
							maxid = Math.max.apply(Math, state.addressess.map(function (o) { return o.localid; }))
							if (maxid === -Infinity) {
								maxid = 0;
							}
						}
						u.localid = maxid + 1;
						state.addressess.push(u);
					}

				} else {
					let maxid = 0;
					let newaddressess = state.addressess.filter(block => block.id == 0)
					if (newaddressess !== 'undefined') {
						maxid = Math.max.apply(Math, state.addressess.map(function (o) { return o.localid; }))
						if (maxid === -Infinity) {
							maxid = 0;
						}
					}
					u.localid = maxid + 1;
					state.addressess.push(u);
				}

			}
			console.log(state.addressess)
		},
		assignaddressess(state, u) {
			state.addressess = u;
		},
		assignselectedwaddress(state, u) {
			state.selectedwaddress = u;
		},
		assignselectedaddress(state, u) {
			state.selectedaddress = u;
		},
		deletemobile(state, u) {
			if (u.id > 0) {
				let index = state.mobiles.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.mobiles.splice(index, 1)
				}
			} else {
				let index = state.mobiles.findIndex(block => block.mobile == u.mobile)
				if (index > -1) {
					state.mobiles.splice(index, 1)
				}
			}
		},
		updateappendmobile(state, u) {
			if (u.id > 0) {
				let index = state.mobiles.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.mobiles.splice(index, 1, u)
				} else
					state.mobiles.push(u);
			} else {
				let index = state.mobiles.findIndex(block => block.mobile == u.mobile)
				if (index > -1) {
					state.mobiles.splice(index, 1, u)
				} else
					state.mobiles.push(u);
			}
		},
		assignmobiles(state, u) {
			state.mobiles = u;
		},
		assignselectedstoreid(state, u) {
			state.selectedstoreid = u;
			console.log("store", state.selectedstoreid)
		},
		assignstores(state, u) {
			state.stores = u;
		},
		assignmaster(state, u) {
			state.master = u;
		},
		updateappendcustomers(state, u) {
			if (u.id > 0) {
				let index = state.customers.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.customers.splice(index, 1, u)
				} else
					state.customers.push(u);
			}
		},
		assigncustomers(state, u) {
			state.customers = u;
		},
		updateappendparties(state, u) {
			if (u.id > 0) {
				let index = state.parties.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.parties.splice(index, 1, u)
				} else
					state.parties.push(u);
			}
		},
		assignparties(state, u) {
			state.parties = u;
		},
		updateappendemployees(state, u) {
			if (u.id > 0) {
				let index = state.employees.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.employees.splice(index, 1, u)
				} else
					state.employees.push(u);
			}
		},
		assignemployees(state, u) {
			state.employees = u;
		},
		assignremoveitem(state, u) {
			state.removeitems = u;
		},
		updateappendcategories(state, u) {
			let index = state.categories.findIndex(block => block.id == u.id)
			if (index > -1) {
				state.categories.splice(index, 1, u)
			} else
				state.categories.push(u);
		},
		assigncategories(state, u) {
			state.categories = u;
		},
		assignattributes(state, u) {
			state.attributes = u;
		},
		assignlist(state, u) {
			state.list = u;
		},

		assignattributeproductcount(state, u) {
			state.attributeproductcount = u;
		},
		assigneditbankimage(state, u) {
			state.editbankimage = u;
		},
		assigneditview(state, u) {
			state.editview = u;
		},
		assignedimageflag(state, u) {
			state.imageflag = u;
		},
		assignedititem(state, u) {
			state.edititem = u;
		},
		assigneditmodal(state, u) {
			state.editmodal = u;
		},
		assignsponsertypes(state, u) {
			state.sponsertypes = u;
		},
		assignperpagerecord(state, u) {
			state.perpagerecord = u;
		},
		assigncurrentpage(state, u) {
			state.currentpage = u;
		},
		assignteam(state, u) {
			state.team = u;
		},
		assignselectedtopupid(state, u) {
			state.selectedtopupid = u;
		},
		assignvaluebank(state, u) {
			state.editvaluebank = u;
		},
		assignvalue(state, u) {
			state.editvalue = u;
		},
		assignusermobiles(state, u) {
			state.usermobiles = u;
		},

		assignuserdetails(state, u) {
			state.userdetails = u;
		},
		assignchargeableledger(state,u){
			state.chargeableledger=u
		},
		assignviewno(state, u) {
			state.viewno = u;
		},
		assigneditproduct(state, u) {
			state.editproduct = u;
		},
		assignproductstore(state, u) {
			state.productstore = u;
		},
		createupdateallproducts(state, u) {
			if (u.id > 0) {
				let index = state.allproducts.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.allproducts.splice(index, 1, u)
				} else
					state.allproducts.push(u);
			}
		},
		assignallproducts(state, u) {
			state.allproducts = u;

		},
		assignuserids(state, u) {
			state.userids = u;
		},
		assignsampleimage(state, u) {
			state.sampleimage = u;
		},
		assigneditcategory(state, u) {
			state.editcategory = u;
		},
		assignloggedinuser(state, u) {
			state.loggedinuser = u;

		},
		assignproducts(state, u) {
			state.storeproducts = u;
			//change filters on the left side

		},
		assignfilteredproducts(state, u) {
			state.filteredproducts = u;
		},
		assignbankdetail(state, u) {
			state.bankdetail = u;
		},
		assignfilters(state, u) {
			state.filters = u;

		},
		assigncartitems(state, u) {
			state.cartitems = u;
		},
		assignbannertypesimages(state, u) {
			state.bannertypeimage = u;
		},
		clear(state) {
			state.cartitems = [];
			state.transaction = null;
		},
		populatecartitembycartitem(state, cartitem) {
			state.cartitems.push(cartitem);
		},
		updateappendschemes(state, u) {
			if (u.id > 0) {
				let index = state.schemes.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.schemes.splice(index, 1, u)
				} else
					state.schemes.push(u);
			}
		},
		assignschemes(state, u) {
			state.schemes = u;
		},
		updateappendadvisors(state, u) {
			if (u.id > 0) {
				let index = state.advisors.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.advisors.splice(index, 1, u)
				} else
					state.advisors.push(u);
			}
		},
		assignadvisors(state, u) {
			state.advisors = u;
		},
		assigndownlinecount(state, u) {
			state.downlinecount = u;
		},
		assigndownline(state, u) {
			state.downline = u;
		},
		updateappenddefaultschemes(state, u) {
			if (u.id > 0) {
				let index = state.defaultschemes.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.defaultschemes.splice(index, 1, u)
				} else
					state.defaultschemes.push(u);
			}
		},
		assigndefaultschemes(state, u) {
			state.defaultschemes = u;
		},
		updateappenddefaultcustomers(state, u) {
			if (u.id > 0) {
				let index = state.defaultcustomers.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.defaultcustomers.splice(index, 1, u)
				} else
					state.defaultcustomers.push(u);
			}
		},
		assigndefaultcustomers(state, u) {
			state.defaultcustomers = u;
		},
		updateappendactiveemployees(state, u) {
			if (u.id > 0) {
				let index = state.activeemployees.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.activeemployees.splice(index, 1, u)
				} else
					state.activeemployees.push(u);
			}
		},
		assignactiveemployees(state, u) {
			state.activeemployees = u;
		},
		updateappendallpayments(state, u) {
			if (u.id > 0) {
				let index = state.allpayments.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.allpayments.splice(index, 1, u)
				} else
					state.allpayments.push(u);
			}
		},
		assignallpayments(state, u) {
			state.allpayments = u;
		},
		updateappendrefundschemes(state, u) {
			if (u.id > 0) {
				let index = state.refundschemes.findIndex(block => block.id == u.id)
				if (index > -1) {
					state.refundschemes.splice(index, 1, u)
				} else
					state.refundschemes.push(u);
			}
		},
		assignrefundschemes(state, u) {
			state.refundschemes = u;
		},
		updateappendnewtax(state, u) {
			let filter = {
				productid: u.productid,
				labelid: u.labelid
			};
			let index = state.newtax.findIndex(function (item) {
				for (var key in filter) {
					if (item[key] === undefined || item[key] != filter[key])
						return false;
				}
				return true;
			})
			if (index > -1) {
				state.newtax.splice(index, 1, u)
			} else {
				state.newtax.push(u);
			}
		},
		assignnewtax(state, u) {
			state.newtax = u
		},
		assigncustomerscount(state, u) {
			state.customerscount = u;
		},
		assignpartiescount(state, u) {
			state.partiescount = u;
		},
		assignemployeescount(state, u) {
			state.employeescount = u;
		},
		addcartitem(state, productitem) {
			// console.log("product item",productitem)
			let item = state.cartitems.find(block => block.product.id === productitem.id);
			if (typeof item !== 'undefined') {
				let index = state.cartitems.findIndex(block => block.product.id === productitem.id);
				item.quantity = parseInt(1) + parseInt(item.quantity);
				item.collectedtaxes.forEach(function (localitem) {
					localitem.value1 = parseFloat(localitem.unitvalue * item.quantity).toFixed(2)
				})
				item.finalamount = parseFloat(item.price * item.quantity)
				item.netpricewithouttax = item.unitpricewithouttax * item.quantity
				state.cartitems.splice(index, 1, item)
			} else {
				item = {}
				item.product = productitem
				item.quantity = productitem.quantity;
				// console.log("state item 2",item.quantity)
				

				item.price = productitem.sp
				//set the all applicable taxes
				let taxesarrayforproduct = [];
				let totalpercent = 0;
				console.log("stage1")
				console.log(state.applicabletaxes)

				state.applicabletaxes.forEach(function (localitem) {
					let obj = { labelid: localitem.labelid, value1: 0, percent: 0 }
					if (productitem != null && productitem.tax != null) {
						let selectedtax = productitem.tax.find(block => block.labelid == localitem.labelid)
						if (typeof selectedtax !== 'undefined') {
							obj.percent = selectedtax.value1
							totalpercent = parseFloat(totalpercent) + parseFloat(obj.percent)
						}
					}
					//calculate the total percent of tax
					//calculate the base price
					//update the amount in all tax

					taxesarrayforproduct.push(obj)
				});
				// console.log("Taxes array",taxesarrayforproduct)
				let pricewithouttax = 0;
				if (item != null && taxesarrayforproduct != null) {

					pricewithouttax = parseFloat((parseFloat(productitem.sp )) / (1 + (totalpercent / 100))).toFixed(2)
					// console.log("pricewithouttax",pricewithouttax)

					// unitvalue assign in collectedtaxes 
					taxesarrayforproduct.forEach(function (localitem) {
						localitem.value1 = parseFloat((pricewithouttax * localitem.percent) / 100).toFixed(2)
						localitem.unitvalue = parseFloat((pricewithouttax * localitem.percent) / 100).toFixed(2)

					})
				}
				item.collectedtaxes = taxesarrayforproduct
				// console.log("collected tax",productitem.tax)
				
				item.unitpricewithouttax = pricewithouttax
				// console.log("price hhh ",pricewithouttax)
				item.netpricewithouttax = pricewithouttax * item.quantity
				item.finalamount = parseFloat(productitem.sp * item.quantity)

				item.barcodes = productitem?.barcodes
				// console.log("item barcode",item.barcodes)
				console.log("Cartitem push",item)
				state.cartitems.push(item);
			}
			console.log("Cart is modified")
			let hsnbasedtaxes = [];
			console.log(state.cartitems)
			state.cartitems.forEach(function (item) {
				if (item.collectedtaxes != null) {
					item.collectedtaxes.forEach(function (taxitem) {

						let found = hsnbasedtaxes.find(block => {
							return block.labelid == taxitem.labelid && block.hsn == item.hsn
						})
						if (typeof found !== 'undefined') {
							found.value1 = parseFloat(found.value1) + parseFloat(taxitem.value1)
							let foundIndex = hsnbasedtaxes.findIndex(block => {
								return block.labelid == taxitem.labelid && block.hsn == item.hsn
							})
							found.value1 = found.value1.toFixed(2)
							hsnbasedtaxes.splice(foundIndex, 1, found)
						} else {
							let obj = { hsn: item.hsn, labelid: taxitem.labelid, value1: taxitem.value1, percent: taxitem.percent }
							hsnbasedtaxes.push(obj)
						}
					})
				}
			})
			state.hsnbasedtaxes = hsnbasedtaxes;
			let total = 0;
			state.cartitems.forEach(function (item) {
				total = parseFloat(item.finalamount) + parseFloat(total);
				total = total.toFixed(2);
			});
			state.totalnetprice = total
		},
		removecartitem(state, item) {

			const index = state.cartitems.findIndex(block => block.product.id === item.product.id);
			// Vue.delete(state.cartitems, index);
			state.cartitems.splice(index, 1)
		},
		updatecalculatedtax(state, item) {
			let obj = state.cartitems.find(block => block.product.id === item.productid);
			if (typeof obj !== 'undefined') {
				obj.collectedtaxes = item.tax
				obj.netprice = item.netprice
				obj.price = item.sp
				obj.quantity = item.quantity
				obj.netpricewithouttax = item.netpricewithouttax
				obj.finalamount = item.netprice
				obj.unitpricewithouttax = item.unitpricewithouttax
				let index = state.cartitems.findIndex(block => block.product.id === item.productid);
				// console.log(obj)
				state.cartitems.splice(index, 1, obj)
			}
			//let obj = {productid:this.item.id,tax:calculatedtax,netprice:netprice,sp:sp,quantity:quantity}

		},
		recalculatetaxes(state) {
			if (state.cartitems != null) {
				let newcartarray = [];
				state.cartitems.forEach(function (cartitem) {
					let item = {}
					item.product = cartitem.product
					item.quantity = cartitem.quantity;
					item.price = cartitem.price
					item.barcodes = cartitem.barcodes
					//set the all applicable taxes
					let taxesarrayforproduct = [];
					let totalpercent = 0;
					let productitem = state.products.find(block => block.id == cartitem.product.id)
					state.applicabletaxes.forEach(function (localitem) {
						let obj = { labelid: localitem.labelid, value1: 0, percent: 0 }
						if (productitem != null && productitem.tax != null) {
							let selectedtax = productitem.tax.find(block => block.labelid == localitem.labelid)
							if (typeof selectedtax !== 'undefined') {
								obj.percent = selectedtax.value1
								totalpercent = parseFloat(totalpercent) + parseFloat(obj.percent)
							}
						}
						//calculate the total percent of tax
						//calculate the base price
						//update the amount in all tax

						taxesarrayforproduct.push(obj)
					});

					let pricewithouttax = 0;
					if (item != null && taxesarrayforproduct != null) {

						pricewithouttax = parseFloat((parseFloat(cartitem.price * cartitem.quantity)) / (1 + (totalpercent / 100))).toFixed(2)

						taxesarrayforproduct.forEach(function (localitem) {
							localitem.value1 = parseFloat((pricewithouttax * localitem.percent) / 100).toFixed(2)
							localitem.unitvalue = parseFloat((pricewithouttax * localitem.percent) / 100).toFixed(2)

						})
					}
					item.collectedtaxes = taxesarrayforproduct
					item.netpricewithouttax = pricewithouttax
					item.unitpricewithouttax = pricewithouttax
					item.finalamount = parseFloat(cartitem.price * cartitem.quantity)
					newcartarray.push(item)
				})
				state.cartitems = newcartarray;
			}
		},
	},
	actions: {
		getcompanyuser({ commit }) {
			window.axios.post('api/user/company')
				.then(response => commit("assigncompanyuser", response.data))
				.catch(error => console.log(error));
		},
		fetchstores({ commit }) {
			window.axios.post('api/stores')
				.then(response => commit("assignstores", response.data))
				.catch(error => console.log(error));
		},
		fetchregisters({ commit }) {
			let param = { isregister: 1 }
			//console.log("Fetching Registers")
			window.axios.post('api/users/fetch', param)
				.then(response => commit('assignregisters', response.data))
				.catch(error => console.log(error));
		},
		// fetchpartiesold({commit},pages){
		// 	let param = {isparty:1,noofrec:pages?.noofrec??50,currentpage:pages?.currentpage??1}
		// 	//console.log("Fetching party")
		// 	window.axios.post('api/users/fetch',param)
		// 		.then(response=>commit('assignparties',response.data))
		// 		.catch(error=>console.log(error));
		// },
		fetchparties({ commit }, pages) {
			let param = {
				fetchcolumns: 'id,name,email,isparty,firmname,gst,',
				fetchtables: 'mobiles,addressess,banks,userdetail', isparty: 1,
				noofrec: pages?.noofrec ?? 50, currentpage: pages?.currentpage ?? 1,
				firmname: pages?.firmname
			}
			//console.log("Fetching party")
			window.axios.post('api/users/fetch', param)
				.then(response => commit('assignparties', response.data))
				.catch(error => console.log(error));
		},
		fetchemployees({ commit }) {
			let param = { isemployee: 1, active: 0 }
			window.axios.post('api/users/fetch', param)
				.then(response => commit('assignemployees', response.data))
				.catch(error => console.log(error));
		},
		fetchcustomers({ commit }, pages) {
			let param = { iscustomer: 1, noofrec: pages?.noofrec ?? 50, currentpage: pages?.currentpage ?? 1 }
			window.axios.post('api/users/fetch', param)
				.then(response => commit('assigncustomers', response.data))
				.catch(error => console.log(error));
		},
		fetchpartiescount({ commit }, field) {

			let param = { isparty: 1, firmname: field?.firmname }
			//console.log("Fetching party")
			window.axios.post('api/users/fetch/count', param)
				.then(response => commit('assignpartiescount', response.data))
				.catch(error => console.log(error));
		},
		fetchemployeescount({ commit }) {
			let param = { isemployee: 1 }
			window.axios.post('api/users/fetch/count', param)
				.then(response => commit('assignemployeescount', response.data))
				.catch(error => console.log(error));
		},
		fetchcustomerscount({ commit }) {
			let param = { iscustomer: 1 }
			window.axios.post('api/users/fetch/count', param)
				.then(response => commit('assigncustomerscount', response.data))
				.catch(error => console.log(error));
		},
		fetchdefaultschemes({ commit }) {
			let param = { isdefaulter: 1 }
			window.axios.post('api/ongoingschemes/fetchbyfilter', param)
				.then(response => commit('assigndefaultschemes', response.data))
				.catch(error => console.log(error));
		},
		fetchdefaultschemescount({ commit }) {
			let param = { isdefaulter: 1 }
			window.axios.post('api/ongoingschemes/fetchbyfilter/count', param)
				.then(response => commit('assigndefaultschemescount', response.data))
				.catch(error => console.log(error));
		},
		fetchdefaultcustomers({ commit }) {
			let param = { isdefaulter: 1 }
			window.axios.post('api/users/fetch', param)
				.then(response => commit('assigndefaultcustomers', response.data))
				.catch(error => console.log(error));
		},
		fetchdefaultcustomerscount({ commit }) {
			let param = { isdefaulter: 1 }
			window.axios.post('api/users/fetch/count', param)
				.then(response => commit('assigndefaultcustomerscount', response.data))
				.catch(error => console.log(error));
		},
		fetchactiveemployees({ commit }) {
			let param = { isdisabled: 0, isemployee: 1 }
			window.axios.post('api/users/fetch', param)
				.then(response => commit('assignactiveemployees', response.data))
				.catch(error => console.log(error));
		},
		fetchactiveemployeescount({ commit }) {
			let param = { isdisabled: 0, isemployee: 1 }
			window.axios.post('api/users/fetch/count', param)
				.then(response => commit('assignactiveemployeescount', response.data))
				.catch(error => console.log(error));
		},
		fetchsumofremainingamount({ commit }) {
			window.axios.post('api/loan/sumofremainingamount')
				.then(response => commit('assignsumofremainingamount', response.data))
				.catch(error => console.log(error));
		},
		fetchallpayments({ commit }, pages) {
			let param = { noofrec: pages?.noofrec ?? 50, currentpage: pages?.currentpage ?? 1 }
			window.axios.post('api/allpayments/fetch', param)
				.then(response => commit('assignallpayments', response.data.payments))
				.catch(error => console.log(error));
		},
		// fetchscheduledloanemi({commit},pages){
		// 	let param ={noofrec:pages?.noofrec??50,currentpage:pages?.currentpage??1}
		// 	window.axios.post('api/scheduled/fetchloansemi',param)
		// 		.then(response=>commit('assignscheduleloanemi',response.data))
		// 		.catch(error=>console.log(error));
		// },
		fetchallexcelpayments({ commit }, pages) {
			let param = { noofrec: pages?.noofrec ?? 50, currentpage: pages?.currentpage ?? 1 }
			window.axios.post('api/allpayments/fetch', param)
				.then(response => commit('assignallexcelpayments', response.data.exceldata))
				.catch(error => console.log(error));
		},
		// fetchallpaymentscount({commit}){
		// 	window.axios.post('api/allpayments/fetch/count')
		// 		.then(response=>commit('assignallpaymentscount',response.data))
		// 		.catch(error=>console.log(error));
		// },
		fetchscheduleloanemicount({ commit }) {
			window.axios.post('api/scheduled/fetchLoansEmicount')
				.then(response => commit('assignscheduleloanemicount', response.data))
				.catch(error => console.log(error));
		},
		// fetchallpaymentsum({commit}){
		// 	window.axios.post('api/allpayments/allpaymentsum')
		// 		.then(response=>commit('assignallpaymentsum',response.data))
		// 		.catch(error=>console.log(error));
		// },
		fetchtotalsalecount({ commit }) {
			window.axios.post('api/saletransactions/datewise/count')
				.then(response => commit('assigntotalsalecount', response.data))
				.catch(error => console.log(error));
		},
		fetchrefundschemes({ commit }) {
			let param = { isrefund: 1 }
			window.axios.post('api/ongoingschemes/fetchbyfilter', param)
				.then(response => commit('assignrefundschemes', response.data))
				.catch(error => console.log(error));
		},
		fetchschemes({ commit }) {
			window.axios.post('api/schemes/fetch')
				.then(response => commit('assignschemes', response.data))
				.catch(error => console.log(error));
		},
		fetchcategories({commit}){
			window.axios.post('api/categories/withfilters')
    			.then(response=>commit('assigncategories',response.data))
    			.catch(error=>console.log(error));
		},
		fetchattributes({ commit }) {
			window.axios.get('api/attributes/getallattributes')
				.then(response => commit('assignattributes', response.data))
				.catch(error => console.log(error));
		},
		fetchbannerimages({ commit }) {
			//let param = {type:2}
			window.axios.post('banner/images')
				.then(response => commit("assignbannertypesimages", response.data))
				.catch(error => console.log(error));
		},
		// fetchsponserTypes({ commit }){
		// 	  let param = {type:1};
		// 	  axios.post('master/values',param)
		// 	  .then(response=>commit("assignsponsertypes",response.data))
		// 	  .catch(error=>console.log(error));
		// },
		fetchfeature({ commit }) {
			window.axios.post('api/feature/fetch')
				.then(response => commit("assignfeature", response.data))
				.catch(error => console.log(error));
		},
		getloggedinuser({ commit }) {
			window.axios.post('api/user/getloggedinuser')
				.then(response => commit("assignloggedinuser", response.data.user))
				.catch(error => console.log(error));
		},
		fetchmaster({ commit }) {
			window.axios.post('api/master/fetch/required')
				.then(response => commit("assignmaster", response.data))
				.catch(error => console.log(error));

		},
		fetchcompanymaster({ commit }) {
			window.axios.post('api/companymaster/fetchrequired')
				.then(response => commit("assigncompanymaster", response.data))
				.catch(error => console.log(error));

		},
		fetchadvisors({ commit }, searchdata) {
			let param = { isadvisor: 1, search: searchdata?.search ?? "" }
			window.axios.post('api/users/fetch', param)
				.then(response => commit('assignadvisors', response.data))
				.catch(error => console.log(error));
		},
		fetchadvisorscount({ commit }) {
			let param = { isadvisor: 1 }
			window.axios.post('api/users/fetch/count', param)
				.then(response => commit('assignadvisorscount', response.data))
				.catch(error => console.log(error));
		},
		fetchproducts({ commit }, pages) {
			let param = { noofrec: pages?.noofrec ?? 10, currentpage: pages?.currentpage ?? 1, categoryid: pages?.categoryid ?? 0 }
			window.axios.post('api/products/categorywise', param)
				.then(response => commit('assignallproducts', response.data))
				.catch(error => console.log(error));
		},
		fetchproductscount({ commit }, pages) {
			let param = { categoryid: pages?.categoryid }
			window.axios.post('api/products/fetchbyfilters/count', param)
				.then(response => commit('assignallproductscount', response.data))
				.catch(error => console.log(error));
		},
	},
	getters: {
		paymenttype: state => {
			return state.paymenttype;
		},
		companymaster: state => {
			return state.companymaster;
		},
		followedbydispatch: state => {
			return state.followedbydispatch;
		},
		uploadids: state => {
			return state.uploadids;
		},
		selectedadvisor: state => {
			return state.selectedadvisor;
		},
		orderconversation: state => {
			return state.orderconversation;
		},
		joblist: state => {
			return state.joblist;
		},
		setmobileno: state => {
			return state.setmobileno;
		},
		controlValue: state => {
			return state.controlValue;
		},
		loanaccounttype: state => {
			return state.loanaccounttype;
		},
		accountstatus: state => {
			return state.accountstatus;
		},
		leads: state => {
			return state.leads;
		},
		leadsedit: state => {
			return state.leadsedit
		},
		crmschedule: state => {
			return state.crmschedule;
		},
		crmschedulecount: state => {
			return state.crmschedulecount;
		},
		advisorid: state => {
			return state.advisorid;
		},
		commissiondetails: state => {
			return state.commissiondetails;
		},
		scheduleloanemicount: state => {
			return state.scheduleloanemicount;
		},
		scheduleloanemi: state => {
			return state.scheduleloanemi;
		},
		allpaymentsum: state => {
			return state.allpaymentsum;
		},
		allexcelpayments: state => {
			return state.allexcelpayments;
		},
		noofrec: state => {
			return state.noofrec;
		},
		totalsalecount: state => {
			return state.totalsalecount;
		},
		allpaymentscount: state => {
			return state.allpaymentscount;
		},
		sumofremainingamount: state => {
			return state.sumofremainingamount;
		},
		searchdata: state => {
			return state.searchdata;
		},
		registers: state => {
			return state.registers;
		},
		companyuser: state => {
			return state.companyuser;
		},
		updatebatchcode: state => {
			return state.updatebatchcode;
		},
		showbarcodemodal: state => {
			return state.showbarcodemodal
		},
		barcodeitem: state => {
			return state.barcodeitem
		},
		totalnetpriceafterdiscount: state => {
			return state.totalnetpriceafterdiscount
		},
		amountwithouttax: state => {
			return state.amountwithouttax
		},
		totalnetprice: state => {
			return state.totalnetprice
		},
		purchasedetails: state => {
			return state.purchasedetails
		},
		transactiondetails: state => {
			return state.transactiondetails;
		},
		transaction: state => {
			return state.transaction
		},
		saletype: state => {
			return state.allproductscount
		},
		allproductscount: state => {
			return state.allproductscount
		},
		trantype: state => {
			return state.trantype
		},
		trantypestring: state => {
			return state.trantypestring
		},
		cartitems: state => {
			return state.cartitems
		},
		applicabletaxes: state => {
			return state.applicabletaxes;
		},
		hsnbasedtaxes: state => {
			return state.hsnbasedtaxes
		},
		advisorscount: state => {
			return state.advisorscount;
		},
		activeemployeescount: state => {
			return state.activeemployeescount;
		},
		defaultcustomerscount: state => {
			return state.defaultcustomerscount;
		},
		stocklist: state => {
			return state.stocklist;
		},
		defaultschemescount: state => {
			return state.defaultschemescount;
		},
		partiescount: state => {
			return state.partiescount;
		},
		usermobiles: state => {
			return state.usermobiles;
		},
		customerscount: state => {
			return state.customerscount;
		},
		employeescount: state => {
			return state.employeescount;
		},
		loadingstatus: state => {
			return state.loadingstatus
		},
		selectedproductid: state => {
			return state.selectedproductid
		},
		filecomponents: state => {
			return state.filecomponents;
		},
		loandetails: state => {
			return state.loandetails
		},
		newtax: state => {
			return state.newtax
		},
		feature: state => {
			return state.feature
		},
		directorypath: state => {
			return state.directorypath
		},
		allproducts: state => {
			return state.allproducts
		},
		allpayments: state => {
			return state.allpayments;
		},
		activeemployees: state => {
			return state.activeemployees;
		},
		defaultschemes: state => {
			return state.defaultschemes;
		},
		defaultcustomers: state => {
			return state.defaultcustomers;
		},
		refundschemes: state => {
			return state.refundschemes;
		},
		downline: state => {
			return state.downline;
		},
		downlinecount: state => {
			return state.downlinecount;
		},
		advisors: state => {
			return state.advisors;
		},
		selecteduserid: state => {
			return state.selecteduserid;
		},
		sampleimage: state => {
			return state.sampleimage;
		},
		accessmodules: state => {
			return state.accessmodules
		},
		followedbydetails: state => {
			return state.followedbydetails
		},
		ongoingschemes: state => {
			return state.ongoingschemes
		},
		schemes: state => {
			return state.schemes
		},
		referraluser: state => {
			return state.referraluser
		},
		idproofs: state => {
			return state.idproofs;
		},
		bankdetail: state => {
			return state.bankdetail;
		},
		profileimage: state => {
			return state.profileimage;
		},
		addressess: state => {
			return state.addressess;
		},
		selectedwaddress: state => {
			return state.selectedwaddress;
		},
		selectedaddress: state => {
			return state.selectedaddress;
		},
		mobiles: state => {
			return state.mobiles;
		},
		master: state => {
			return state.master;
		},
		customers: state => {
			return state.customers;
		},
		selectedstoreid: state => {
			return state.selectedstoreid
		},
		stores: state => {
			return state.stores
		},
		parties: state => {
			return state.parties;
		},
		employees: state => {
			return state.employees;
		},
		removeitems: state => {
			return state.removeitems;
		},
		categories: state => {
			return state.categories;
		},
		attributes: state => {
			return state.attributes;
		},
		list: state => {
			return state.list;
		},
		imageflag: state => {
			return state.imageflag;
		},
		paymentstatus: state => {
			return state.paymentstatus;
		},
		editbankimage: state => {
			return state.editbankimage;
		},
		widthrawstatus: state => {
			return state.widthrawstatus;
		},
		cmpbank: state => {
			return state.cmpbank;
		},
		nomineerelation: state => {
			return state.nomineerelation;
		},
		maritalstatus: state => {
			return state.maritalstatus;
		},
		editview: state => {
			return state.editview
		},
		gender: state => {
			return state.gender;
		},
		bloodgrp: state => {
			return state.bloodgrp;
		},
		menu: state => {
			return state.menu;
		},
		crtpage: state => {
			return state.crtpage;
		},
		perpagerecord: state => {
			return state.perpagerecord;
		},
		currentpage: state => {
			return state.currentpage;
		},
		walletTypes: state => {
			return state.wallettypes;
		},
		insuranceterm: state => {
			return state.insuranceterm;
		},
		orgtype: state => {
			return state.orgtype;
		},
		tehsil: state => {
			return state.tehsil;
		},
		status: state => {
			return state.status;
		},
		country: state => {
			return state.country;
		},
		gasagency: state => {
			return state.gasagency;
		},
		city: state => {
			return state.city;
		},
		state: state => {
			return state.state;
		},
		operatortype: state => {
			return state.operatortype;
		},
		operator: state => {
			return state.operator;
		},
		occupation: state => {
			return state.occupation;
		},
		insurancecomapny: state => {
			return state.insurancecomapny;
		},

		loantype: state => {
			return state.loantype;
		},
		insurancetype: state => {
			return state.insurancetype;
		},
		vehicletype: state => {
			return state.vehicletype;
		},
		loggedinuser: state => {

			return state.loggedinuser;
		},
		selectedwalletid: state => {
			return state.selectedwalletid;
		},
		edititem: state => {
			return state.edititem
		},
		editmodal: state => {
			return state.editmodal
		},
		selecteduser: state => {
			return state.selecteduser;
		},
		editflag: state => {
			return state.editflag;
		},
		topupplans: state => {
			return state.topupplans;
		},
		franchisetopupplans: state => {
			return state.franchisetopupplans;
		},
		userlist: state => {
			return state.userlist;
		},

		checkifadminpage() {
			return location.href.indexOf('admin') > -1
		},
		paymentmodes: state => {
			return state.paymentmodes
		},
		viewno: state => {
			return state.viewno
		},
		geneologyuser: state => {
			return state.geneologyuser
		},
		levels: state => {
			return state.levels
		},
		upi: state => {
			return state.upi
		},
		bank: state => {
			return state.bank
		},
		balances: state => {
			return state.balances
		},
		userids: state => {
			return state.userids
		},
		complaints: state => {
			return state.complaints;
		},
		custumbgcolor: state => {
			return state.custumbgcolor;
		},
		cust_walletheading: state => {
			return state.custwalletheading;
		},
		debituser: state => {
			return state.debituser;
		},
		imagetype: state => {
			return state.imagetype
		},
		sortedfranchisetopupplans: state => {
			return state.sortedfranchisetopupplans;
		},
		apibalance: state => {
			return state.apibalance;
		},
		uploadedimage: state => {
			return state.uploadedimage;
		},
		userdetails: state => {
			return state.userdetails;
		},
		editvalue: state => {
			return state.editvalue;
		},
		selectedtopupid: state => {
			return state.selectedtopupid;
		},
		team: state => {
			return state.team;
		},
		bannertypeimage: state => {
			return state.bannertypeimage;
		},
		chargeableledger:state=>{
			return state.chargeableledger
		},
		noofrecord:state=>{
			return state.noofrecord
		},
	}
})

export default store;
